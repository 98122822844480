import Mobile from './card-medical-mobile';
import Small from './card-medical-small';
import Large from './card-medical-large';

const medical = {
    Mobile,
    Small,
    Large
};

export default medical;