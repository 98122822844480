import Mobile from './card-transportation-mobile';
import Small from './card-transportation-small';
import Large from './card-transportation-large'

const transportation = {
    Mobile,
    Small,
    Large
};

export default transportation;