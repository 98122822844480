import Mobile from './card-docs-mobile';
import Small from './card-docs-small';
import Large from './card-docs-large';

const docs = {
    Mobile,
    Small,
    Large
};

export default docs;