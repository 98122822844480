// extracted by mini-css-extract-plugin
export var size = "projects-template-desktop-module--size--3paoK";
export var circleInActive = "projects-template-desktop-module--circleInActive--hZlMA";
export var circleActive = "projects-template-desktop-module--circleActive--1KyQF";
export var largeActive = "projects-template-desktop-module--largeActive--1ivDV";
export var largeInActive = "projects-template-desktop-module--largeInActive--OFNGR";
export var smallInActive = "projects-template-desktop-module--smallInActive--1oc2U";
export var smallActive = "projects-template-desktop-module--smallActive--CLw0Z";
export var image = "projects-template-desktop-module--image--lhQev";
export var imageActive = "projects-template-desktop-module--imageActive--2aIQF";
export var imageInActive = "projects-template-desktop-module--imageInActive--2JzNG";
export var text = "projects-template-desktop-module--text--1Icey";
export var nameActive = "projects-template-desktop-module--nameActive--1idAi";
export var nameInActive = "projects-template-desktop-module--nameInActive---6CZE";
export var description = "projects-template-desktop-module--description--1yJp8";