import Mobile from './card-training-mobile';
import Small from './card-training-small';
import Large from './card-training-large';

const training = {
    Mobile,
    Small,
    Large
};

export default training;