import Mobile from './card-neutralization-mobile';
import Small from './card-neutralization-small';
import Large from './card-neutralization-large';

const neutral = {
    Mobile,
    Small,
    Large
};

export default neutral;