import Green from './card-green-phone';
import Technopark from './card-technopark';
import Education from './card-education';

const cardsProjects = {
    Green,
    Technopark,
    Education
}

export default cardsProjects;